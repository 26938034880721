@import '../../../styles/customMediaQueries.css';

.root {
  padding: 0 24px;
  margin-bottom: 38px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.guestCountInputWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  margin: 15px 0;

  & label {
    margin-right: auto;
  }

  & small {
    display: block;
    font-size: 14px;
    line-height: 1.4;
  }
}

.guestCountIncrement,
.guestCountDecrement {
  border: 2px solid var(--marketplaceColor);
  border-radius: 100%;
  color: var(--marketplaceColor);
  font-size: 18px;
  line-height: 0; /* To fix Safari line height bug */
  height: 20px;
  width: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  & span {
    display: block;
    height: 10px;
    line-height: 6px;
  }
}

.guestCountNumber {
  font-size: 22px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
  text-align: center;
  border: 0;
  flex: 0 1 auto;
  padding: 0;
  margin-bottom: 3px;
  width: 40px;

  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: default;
  }
}

.labelButton {
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 10px 6px;
  border: 0;
  border-bottom: 3px solid var(--colorAttention);
  padding: 4px 0 9px 0;
  width: 100%;
  text-align: left;

  &:disabled {
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%23E7E7E7" fill="%23E7E7E7" fill-rule="evenodd"/></svg>');
  }
}

.guestCountDisplay {
  font-size: 20px;

  & svg {
    vertical-align: baseline;
  }

  & span {
    line-height: 24px;
    margin-left: 12px;
  }
}

.guestCountPanel {
  display: none;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
  padding: 5px 30px 40px;
}

.guestCountHelp {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.3;
  margin: 30px 0 0;
  max-width: 200px;

  & small {
    display: block;
    font-size: 14px;
    opacity: 0.5;
    margin-top: 4px;
  }
}

.isOpen {
  & .labelButton {
    background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6"  xmlns="http://www.w3.org/2000/svg"><path d="M5.315,1.129c-0.173,-0.172 -0.456,-0.172 -0.628,-0l-3.556,3.556c-0.083,0.083 -0.13,0.196 -0.13,0.314c-0,0.244 0.201,0.444 0.444,0.444c0.118,0 0.232,-0.047 0.315,-0.13l3.241,-3.239l3.241,3.24c0.083,0.083 0.196,0.13 0.314,0.13c0.243,-0 0.444,-0.201 0.444,-0.444c-0,-0.118 -0.047,-0.231 -0.13,-0.314l-3.555,-3.556l-0,-0.001Z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  }

  & .guestCountPanel {
    display: block;
  }
}
